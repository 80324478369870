<template>
  <section :class="curso.documentario === 'S' ? 'px-0' : ''">
    <div v-if="!curso.id" class="min-vh-100 row align-items-center">
      <LoadingComp />
    </div>

    <div v-if="curso.documentario === 'N'">
      <ClassContent id="cursos" class="pb-0" v-if="curso.disponivelEmBreve === 'N'" />

      <EmBreve v-else />
    </div>

    <div v-if="curso.documentario === 'S'">
      <Documentaries class="container-fluid px-0" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/service/userService";
import CursoService from "@/service/cursoService";

import EmBreve from "./EmBreve";
import ClassContent from "./series/ClassContent";
import Documentaries from "./documentarios/Documentaries";

export default {
  name: "CursoComp",

  components: {
    EmBreve,
    ClassContent,
    Documentaries,
  },

  async created() {
    this.UserService = new UserService(this.$resource);
    this.CursoService = new CursoService(this.$resource);

    const id = this.$route.params.id;
    this.getCurso(id);
    this.getVinculoCurso(id);
    this.loadVideos();
    if (this.$enable) {
      setInterval(() => {
        this.$checkIfDebuggerEnabled().then((result) => {
          if (result) this.logut();
        });
      }, 2000);
    }
  },

  data() {
    return { isSet: false };
  },

  methods: {
    logut() {
      localStorage.setItem("tokenPlataformaEducando", false);
      this.$store.dispatch("registration/logOut");
      this.$router.push({ name: "login" });
      location.reload();
    },

    async getCurso(id) {
      this.$store.dispatch("cursos/setCurso", {});
      const {
        body: [curso],
      } = await this.CursoService.get({ id });

      this.$store.dispatch("cursos/setCurso", curso);
    },

    async loadVideos() {
      this.$store.dispatch("temporadas/setTemporadas", []);
      this.$store.dispatch("temporadas/setIsLoading", true);
      const { body: temporadas } = await this.CursoService.todasTemporadas({
        id: this.$route.params.id,
      });

      this.$store.dispatch("temporadas/setIsLoading", false);

      this.$store.dispatch(
        "temporadas/setTemporadas",
        temporadas.map((item) => ({ ...item, isOpen: false }))
      );
    },

    async getVinculoCurso(id) {
      const { body: vinculoCursoId } = await this.UserService.openCourse({
        id,
      });
      this.$store.dispatch("temporadas/setIdVinculo", vinculoCursoId);
    },

    findNextVideo() {
      const { modulo, video, idTemporadaVideo, id } = this.$route.params;
      if (!isSearch(modulo) && !isSearch(video) && !isSearch(idTemporadaVideo))
        return this.setOpen(modulo);

      const nextVideo =
        this.videos.find(({ visto }) => !visto) || this.videos.pop();
      if (!nextVideo) return;

      this.setOpen(nextVideo.idTemporada);
      this.$router.push({
        name: "video",
        params: {
          id,
          modulo: nextVideo.idTemporada,
          video: nextVideo.id,
          idTemporadaVideo: nextVideo.idTemporadaVideo,
        },
      });
    },

    setOpen(idTemporada) {
      if (this.isSet) return;

      this.isSet = true;
      const temporadas = this.temporadas.map((item) => ({
        ...item,
        isOpen: Number(idTemporada) === item.idTemporada,
      }));
      this.$store.dispatch("temporadas/setTemporadas", temporadas);
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
    }),

    videos() {
      return this.$store.getters["temporadas/getAllVideos"];
    },

    temporadas() {
      return this.$store.getters["temporadas/getTemporadas"];
    },

    curso() {
      return this.$store.getters["cursos/getCurso"];
    },
  },

  watch: {
    videos(videos) {
      if (!videos.length) return;

      setTimeout(() => {
        this.findNextVideo();
      }, 200);
    },
  },
};
const isSearch = (val) => val === "search";
</script>

<style lang="sass">
.white
  &:hover
    color: #aaa

hr
  border: 1px solid #888 !important
  background-color: #888

.curso-em-breve
  width: 100%

.img-coord
  background-color: #fff
  border-radius: 100%
  width: 30px !important
  height: 30px !important
  overflow: hidden

  img
    border-radius: 100%
    width: 30px !important
    height: 30px !important
</style>
