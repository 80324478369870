<template>
  <div class="py-2 px-1 px-md-5">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-10">
        <input id="base-focus" />
        <FieldTextQuill :disabled="isLoading || isPro" :hasImage="false" :hasList="!!video.roteiro" :hasGalery="false"
          @keyPressed="keyPressed" v-model="message"
          :label="video.roteiro ? 'Escreva sua dúvida' : 'Escreva seu comentário'"
          :placeholder="video.roteiro ? 'Pergunte ao Pjotinha' : 'Compartilhe algo'" :isDisabled="isPro"
          :key-press="true" :has-image="!video.roteiro" :enable-key="false" />
      </div>

      <form @submit.prevent="saveComment" class="col-sm-12 col-md-10">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button :disabled="isPro || isLoading" class="btn bg-btn-pink font-14 mb-1" id="gerarResposta">
              Enviar
            </button>
          </div>
        </div>
      </form>
      <div class="col-sm-12 col-md-10">
        <hr />
      </div>

      <div class="col-sm-12 col-md-10 mb-5 row justify-content-center" v-if="isLoading">
        <div class="loading-pjotinha row justify-content-center align-items-center">
          <PjotinhaIcon />
        </div>
      </div>

      <div class="col-sm-12 col-md-10">
        <CommentCard :video="video" :comment="comment" v-for="comment in comments" :key="comment.id" />
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CommentCard from "./comments/CommentCard";
import PjotinhaIcon from "@/assets/icons/PjotinhaIcon";
import CommentsService from "@/service/commentsService";
import FieldTextQuill from "@/components/shared/forms/FieldTextQuill";
import { mapGetters } from "vuex";

export default {
  name: "CommentsNav",

  props: {
    video: {
      type: Object,
      required: true,
    },
  },

  components: {
    PjotinhaIcon,
    FieldTextQuill,
    CommentCard,
  },

  created() {
    this.loadComentarios();
  },

  data() {
    this.CommentsService = new CommentsService(this.$resource);

    return {
      message: "",
      activeNotification: false,
      comments: [],
      isLoading: false,
    };
  },

  watch: {
    $route: {
      deep: true,
      handler() {
        this.loadComentarios();
      },
    },
    video: {
      deep: true,
      handler(video) {
        if (video.roteiro) {
          this.loadComentarios();
        }
      },
    },
    user: {
      deep: true,
      handler(user) {
        if (user.id) {
          this.loadComentarios();
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    isPro() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },
  },

  methods: {
    async loadComentarios() {
      const params = {
        idCurso: Number(this.$route.params.id),
        idTemporadaVideo: Number(this.$route.params.idTemporadaVideo),
      };
      if (isNaN(params.idTemporadaVideo) || !this.video.id || !this.user.id) return;

      this.comments = [];

      this.isLoading = true;

      const { body } = this.video.roteiro ? await this.CommentsService.comentariosRoteiro(params) : await this.CommentsService.comentarios(params);

      const comments = (body.lista?.map(item => this.mountCommentIA(item)) || body)
        .map((params) => {
          const newResp = mountComment(params, this.$baseApiURL, this.video.roteiro);
          newResp.respostas =
            params?.respostas?.map((res) =>
              mountComment(res, this.$baseApiURL, this.video.roteiro)
            ) || [];
          return newResp;
        });

      this.comments = Object.values(comments || []);
      this.isLoading = false;
    },

    keyPressed() {
      document.getElementById("base-focus").focus();
      setTimeout(() => {
        document.getElementById("gerarResposta").click();
      }, 200);
    },

    mountCommentIA(item) {
      return {
        "id": item.idQuestionamento,
        "idTemporadaVideo": this.$route.params.idTemporadaVideo,
        "idEducando": this.user.id,
        "idModerador": this.user.idUsuario,
        "idUsuario": this.user.idUsuario,
        "idComentarioPai": 0,
        "comentario": item.pergunta,
        "exibicoes": 0,
        "totalCurtidas": 0,
        "euCurtiEsseComentario": "N",
        "status": "A",
        "createdAt": item.dataHoraPergunta,
        "updatedAt": item.dataHoraPergunta,
        "nome": `${this.user.nome} ${this.user.sobrenome}`.trim(),
        "assinatura": this.user.isPro,
        "ocupacao": this.user.ocupacao,
        "extensaoImagem": this.user.extensaoImagem,
        "idCurso": this.$route.params.id,
        "idVideo": this.video.id,
        "idTemporada": this.$route.params.idTemporada,
        "respostas": [{
          "id": 1,
          "idTemporadaVideo": this.$route.params.idTemporadaVideo,
          "idEducando": 'Pjotinha',
          "idModerador": 0,
          "idUsuario": 'Pjotinha',
          "idComentarioPai": item.idQuestionamento,
          "comentario": item.resposta,
          "exibicoes": 0,
          "totalCurtidas": 0,
          "euCurtiEsseComentario": "N",
          "status": "A",
          "createdAt": item.dataHoraPergunta,
          "updatedAt": item.dataHoraPergunta,
          "nome": "Pjotinha",
          "assinatura": true,
          "ocupacao": " I.A. da Plataforma PJA+",
          "extensaoImagem": "png",
          "imagem": "https://publico.sfo3.cdn.digitaloceanspaces.com/pjotinha.png",
          "idCurso": this.$route.params.id,
          "idVideo": this.video.id,
          "idTemporada": this.$route.params.idTemporada,
          "respostas": []
        }]
      }
    },

    async saveComment() {
      const params = {
        idTemporadaVideo: Number(this.$route.params.idTemporadaVideo),
        comentario: this.message.replace(/\n/g, "<br>"),
        pergunta: this.message.replace(/\n/g, "<br>"),
        idCurso: Number(this.$route.params.id),
      };

      const paramsToList = {
        extensaoImagem: this.user.extensaoImagem,
        idEducando: this.user.id,
        nome: this.user.nome,
        ocupacao: this.user.ocupacao,
        id: uuidv4(),
        idComentarioPai: null,
        comentario: params.comentario,
        createdAt: new Date(),
        exibicoes: 0,
        curtidas: 0,
      };

      this.message = "";
      if (!this.video.roteiro) this.comments.unshift(mountComment(paramsToList, this.$baseApiURL, this.video.roteiro));
      else this.isLoading = true;
      await (this.video.roteiro ? this.CommentsService.saveRoteiro(params) : this.CommentsService.save(params));
      if (this.video.roteiro) {
        this.loadComentarios();
      }
    },
  },
};

const mountComment = (params, url, isRoteiro = false) => ({
  usuario: {
    imagem: params.imagem || `${url}/acesso-arquivo?caminho=educando&extensao=${params.extensaoImagem}&identificador=${params.idEducando}`,
    nome: params.nome,
    profissao: params.ocupacao,
    idEducando: params.idEducando,
    hasImage: Boolean(params.extensaoImagem),
  },
  id: params.id,
  idComentarioPai: params.idComentarioPai,
  texto: !isRoteiro ? params.comentario : !params.idComentarioPai ? params.comentario : `
    ${params.comentario} 
    <p class="mt-3 pjotinha-contact">
      Se eu não tiver conseguido responder da forma que você esperava, sinta-se à vontade para tentar perguntar novamente, 
      usando outras palavras ou explicando com mais detalhes. Caso ainda assim eu não consiga te ajudar, 
      você pode postar sua dúvida no Café (o feed da plataforma, acessível pelo menu lateral esquerdo) ou compartilhá-la com a nossa comunidade no WhatsApp.
    </p>`,
  dataComentario: moment(params.createdAt)
    .locale("pt-br")
    .format("DD MMM YYYY"),
  visualizacoes: params.exibicoes,
  likes: params.curtidas,
});
</script>

<style lang="sass">
@keyframes pulse-loading
  0%
    opacity: 0.3
    transform: scale(5)
  50%
    opacity: 1
    transform: scale(5.2)
  100%
    opacity: 0.3
    transform: scale(5)

.loading-pjotinha
  margin-top: 50px
  animation: 2s pulse-loading infinite

.pjotinha-contact
  opacity: 0.6 !important
</style>
<style lang="sass" scoped>
.pjotinha-contact
  color: #676767 !important !important
  font-size: 12px !important
.btn
  border-radius: 3px !important
.form-check-input
  border-radius: 2px !important
  &:focus
    box-shadow: none
#base-focus
  height: 0
  padding: 0
  opacity: 0
  position: absolute
</style>
