import './assets/styles/styles.min.css'
import './assets/styles/bootstrap-grid.min.css'

import App from './App.vue'
import store from './store'
import routes from './routes'
import paths from './consts/pathsError.json'
import goInterceptors from './interceptors/go.js'
import postInterceptors from './interceptors/post.js'

import Sweet from '@/assets/js/sweet'
import Funcoes from '@/assets/js/functions'
import LockIcon from '@/assets/icons/LockIcon.vue'
import TagMode from '@/components/shared/TagMode.vue'
import Propaganda from '@/components/shared/Propaganda.vue'
import Loading from '@/components/shared/Loading.vue'
import MainContainer from '@/components/shared/mainContainer/MainContainer'

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueMask from 'v-mask'
import camelize from 'camelize'
import VueRouter from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import VueScrollTo from 'vue-scrollto'
import VueResource from 'vue-resource'

const workerUrl =
  'data:application/javascript;base64,' +
  btoa(`
  self.addEventListener('message', (e) => {
    if(e.data==='hello'){
      self.postMessage('hello');
    }
    debugger;
    self.postMessage('');
  });
`)

Vue.prototype.$checkIfDebuggerEnabled = () => {
  return new Promise((resolve) => {
    let fulfilled = false
    const worker = new Worker(workerUrl)
    worker.onmessage = (e) => {
      const data = e.data
      if (data === 'hello') {
        setTimeout(() => {
          if (!fulfilled) {
            resolve(true)
            worker.terminate()
          }
        }, 1)
      } else {
        fulfilled = true
        resolve(false)
        worker.terminate()
      }
    }
    worker.postMessage('hello')
  })
}

Vue.use(Vuex)
Vue.use(VueMask)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueScrollTo)

Vue.prototype.$version = '4.0.3'
Vue.prototype.$f = new Funcoes()
Vue.prototype.$sweet = new Sweet()
Vue.prototype.$space = 'https://privado.sfo3.cdn.digitaloceanspaces.com'
Vue.prototype.$spaceGalery = 'https://galeria.pja.sfo3.cdn.digitaloceanspaces.com'
Vue.prototype.$checkoutAnual = 'https://pay.hotmart.com/A65673843L?off=c7rht0ef&checkoutMode=10&split=12&bid=1709773773413'

const mountPathSpace = ({ id, extensao, idPost, idAluno }) => `${Vue.prototype.$space}/postagens/${idAluno}/${idPost}/${id}.${extensao}`
Vue.prototype.$mountPathSpace = (params) => mountPathSpace(params)

Vue.prototype.$site = 'https://pjamais.com.br/'
Vue.prototype.$enable = false // process.env.NODE_ENV === 'production'
// const baseApiURLGo = 'http://localhost:8008'
const baseApiURLGo = 'https://api.pjamais.com.br'
const baseApiURL = 'https://api.pjamaiseducacao.com.br'
Vue.http.options.root = baseApiURL
axios.defaults.baseURL = baseApiURL
Vue.prototype.$baseApiURL = baseApiURL
Vue.prototype.$siteURL = 'https://pjamais.com.br/'
Vue.prototype.$email = 'contato@projetojovemarquiteto.com.br'
Vue.prototype.$siteURLLogin = 'https://portal.pjamais.com.br/#/login'
Vue.prototype.$linkPlataformaAluno = 'https://portal.pjamais.com.br/'
Vue.prototype.$linkYoutube = 'https://youtube.com/c/ProjetoJovemArquiteto'
Vue.prototype.$linkFacebook = 'https://m.facebook.com/projetojovemarquiteto'
Vue.prototype.$linkInstagram = 'https://instagram.com/projetojovemarquiteto?utm_medium=copy_link'
Vue.prototype.$isBlackFriday = false
Vue.prototype.$isBlackFridayStarted = false
Vue.prototype.$linkBlackFridayWhats = 'https://chat.whatsapp.com/FcznikMhAuE5O5qv9JQUrS'
Vue.prototype.$linkBlackFridayCheckout = 'https://pay.hotmart.com/A65673843L?off=foyqpmjn&checkoutMode=10&bid=1699023054247'

let isInvalidToken = false
Vue.component('TagMode', TagMode)
Vue.component('LockIcon', LockIcon)
Vue.component('LoadingComp', Loading)
Vue.component('PropagandaFree', Propaganda)
Vue.component('MainContainer', MainContainer)
export const router = new VueRouter({ routes, base: '/' })
const success = [200, 202]



Vue.http.interceptors.push(function (request) {
  postInterceptors(request, Vue.http)

  const isGoAPI = goInterceptors(request)
  if (isGoAPI) request.root = baseApiURLGo

  return function (response) {
    if (!success.includes(response.status)) persistError(request, response);
    response.body = camelize(response.body);

    if (isGoAPI) response.body = isGoAPI(response.body);

    const msgErrorToken = 'erro ao validar token'
    const tokenInvalido =
      response.body === msgErrorToken ||
      response.data === msgErrorToken ||
      response === msgErrorToken
    if (tokenInvalido) return invalidToken()

    const msgErrorAccess = 'origem informada diferente da registrada'
    const accessInvalido =
      response.body === msgErrorAccess ||
      response.data === msgErrorAccess ||
      response === msgErrorAccess
    if (accessInvalido) invalidAccess()
  }
})

const persistError = async (request, response) => {
  let fluxo = paths[request.url]
  if (!fluxo) return

  const endpoint = response.url
  fluxo = `${request.method} - ${fluxo}`
  const observacao = JSON.stringify({ ...request.params })

  Vue.http
    .post('plataforma-conteudo/registrar-erro-request', {
      endpoint,
      fluxo,
      observacao
    })
    .then((res) => res)
    .catch((err) => err)
}

const invalidToken = async () => {
  if (isInvalidToken) return
  isInvalidToken = true
  await Vue.prototype.$sweet.sweetInvalidToken()
  router.push('/login').catch()
}

const invalidAccess = async () => {
  if (isInvalidToken) return
  isInvalidToken = true
  await Vue.prototype.$sweet.sweetInvalidAccess()
  router.push('/login').catch()
}

const ValidaDadosUsuario = async (token) => {
  const isLoged = store.state.registration.user.id
  if (isLoged) return
  
  Vue.http
    .get('acesso-publico/token-decodificar', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(({ body: [[user]] }) => {
      Vue.http.get('plataforma-conteudo/educando/sessao/meus-dados')
        .then(({ body }) => {
          Vue.http.get('plataforma-conteudo/educando/sessao/conquistas-dados-uteis-validar-aptidao').then(({body: [dataInfos]}) => {
            isInvalidToken = false
            store.dispatch('registration/setUser', { ...user,...body, ...dataInfos, token, isPro: user.assinatura === 'P', acesso: user.acesso })
          }).catch((e) => {
            localStorage.setItem('tokenPlataformaEducando', false)
            router.push('/login').catch()
            console.log(e)
          })
        })
        .catch((e) => {
          localStorage.setItem('tokenPlataformaEducando', false)
          router.push('/login').catch()
          console.log(e)
        })
    })
    .catch((err) => {
      localStorage.setItem('tokenPlataformaEducando', false)
      router.push('/login').catch()
      console.log(err)
    })
}

const ValidaPermissao = async (next) => {
  const token = localStorage.getItem('tokenPlataformaEducando')
  const noToken = [null, undefined, 'undefined', 'null', ''].includes(token)
  if (noToken) return router.push('/login').catch()

  Vue.http.headers.common.Authorization = `Bearer ${token}`
  Vue.http.headers.common.Origem = await getUUID()
  ValidaDadosUsuario(token)
  next()
}

router.beforeEach((to, from, next) => {
  if (from.name !== 'curso' && from.name !== 'video') window.scrollTo(0, 0)
  const toIgnore = ['token', 'login', 'recover', 'empresas']
  if (toIgnore.includes(to.name)) return next()
  ValidaPermissao(next)
  next()
})

const getUUID = async () => {
  let uuid = localStorage.getItem('uuidPlataformaEducando')
  if (uuid) return uuid
  uuid = uuidv4()
  localStorage.setItem('uuidPlataformaEducando', `p-${uuid}`)
  return uuid
}

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
