const base = 'plataforma-conteudo/comunidade/comentario-temporada-video{/idTemporadaVideo}'
const baseComentario = `${base}{/idComentario}`

export default class commentsService {
  constructor (resource) {
    const acoesCustomizadas = {
      respostas: { method: 'GET', url: `${baseComentario}/respostas` },
      salvarResposta: { method: 'POST', url: `${baseComentario}/resposta` },
      curtidas: { method: 'GET', url: `${baseComentario}/curtidas` },

      curtir: { method: 'POST', url: `${baseComentario}/curtida` },
      removerCurtida: { method: 'DELETE', url: `${baseComentario}/curtida` },
      alunoCurtiu: { method: 'GET', url: `${baseComentario}/eu-curti-esse-comentario` },
      visualizar: { method: 'PUT', url: `${baseComentario}/incrementar-exibicao` },
      conquistas: { method: 'GET', url: 'plataforma-conteudo/educando/lista-conquistas-educando{/idEducando}' },
      comentarios: { method: 'GET', url: `${base}/comentarios` },
      saveRoteiro: { method: 'POST', url: 'plataforma-conteudo/curso{/idCurso}/temporada-video{/idTemporadaVideo}/perguntar-ao-pjotinha' },
      comentariosRoteiro: { method: 'GET', url: 'plataforma-conteudo/curso{/idCurso}/temporada-video{/idTemporadaVideo}/listar-perguntas-realizadas/50/pulando/0' },
    }
    this.resource = resource(`${base}/comentario`, {}, acoesCustomizadas)
  }

  conquistas (dados) {
    return this.resource
      .conquistas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  saveRoteiro (dados) {
    return this.resource
      .saveRoteiro({ idCurso: dados.idCurso, idTemporadaVideo: dados.idTemporadaVideo }, dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  comentarios (dados) {
    return this.resource
      .comentarios(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  comentariosRoteiro (dados) {
    return this.resource
      .comentariosRoteiro(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
  respostas (dados) {
    return this.resource
      .respostas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  salvarResposta (dados) {
    const { idComentario, idTemporadaVideo } = dados
    return this.resource
      .salvarResposta({ idComentario, idTemporadaVideo }, dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  curtidas (dados) {
    return this.resource
      .curtidas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  alunoCurtiu (dados) {
    return this.resource
      .alunoCurtiu(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  curtir (dados) {
    return this.resource
      .curtir(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  removerCurtida (dados) {
    return this.resource
      .removerCurtida(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  visualizar (dados) {
    return this.resource
      .visualizar(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  getAll (dados) {
    return this.resource
      .getAll(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  get (dados) {
    return this.resource
      .get(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  save ({ idTemporadaVideo, ...dados }) {
    return this.resource
      .save({ idTemporadaVideo }, dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  update (dados) {
    return this.resource
      .update(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  delete (dados) {
    return this.resource
      .delete(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
